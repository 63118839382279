import React, { useState } from 'react'
import Head from 'components/Head'
import { observer } from 'mobx-react-lite'
import GlobalStyles from 'components/GlobalStyles'
import PartnershipsHeader from 'components/PartnershipsSite/PartnershipsHeader'
import PartnershipsFooter from 'components/PartnershipsSite/PartnershipsFooter'
import { useStore } from 'hooks'
import { THEME } from 'utils'
import NavigationStore from 'stores/navigation'
import { usePathname } from 'next/navigation'
import startCase from 'lodash/startCase'

function generateSEOTag(path) {
  const pathStrings = path
    .split('/')
    .map((pathString, index) => {
      return index >= 2 ? startCase(pathString.replace('-', ' ')) : null
    })
    .filter((pathString) => pathString?.length > 0)

  return `Barstool Sports Partnerships: Elevate Your Brand, Reach a Massive Audience${
    pathStrings?.length > 0 ? ` - ${pathStrings.join(' - ')}` : ''
  }`
}

const PartnershipsLayout = ({
  children,
  background = 'rgb(216, 216, 216)',
  fullScreen = false,
  hideHeader = false,
  hideFooter = false,
  ...props
}) => {
  const currentPath = usePathname()
  const navigationStore = useStore(NavigationStore)
  const [mobileMenu, setMobileMenu] = useState(false)

  const handleNavStore = () => {
    navigationStore.close()
    setMobileMenu(false)
  }

  return (
    <>
      <Head
        title={generateSEOTag(currentPath)}
        description={
          props.description ||
          'Advertise with Barstool Sports today via paid sponsorships & other partnership options.  Barstool Sports advertising reaches consumers with creative, authentic brand integrations across audio, video, social, and live experiences.'
        }
        ogImage='https://chumley.barstoolsports.com/union/2021/02/10/adsite-social-share.d89533ed.png'
      />
      <div className='layout'>
        {!hideHeader && <PartnershipsHeader mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />}
        <div className='layout__overlayContainer' onClick={handleNavStore}>
          <div className='layout__overlay' />
          {fullScreen ? children : <main className='layout__main'>{children}</main>}
        </div>
        {!hideFooter && <PartnershipsFooter />}
        <GlobalStyles />
      </div>
      <style jsx>{`
        .layout {
          background: ${background};
        }
        .layout__overlayContainer {
          position: relative;
          ${!fullScreen && 'max-width: 1200px;'}
          margin: auto;
        }
        .layout__overlay {
          display: none;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 10;
          background: rgba(0, 0, 0, 0.5);
        }
        .layout__main {
          // margin-top: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: rgb(216, 216, 216);
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .layout__main {
            min-height: calc(100vh - 177px);
            padding: 0 3rem;
          }
          // .layout__overlayContainer {
          //   padding: 0 3rem;
          // }
        }
        @media (max-width: ${THEME.BREAKPOINTS.SM - 1}px) {
          .layout__main {
            margin-top: 0px;
            margin-bottom: 0px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          // .layout__overlayContainer {
          //   padding: 0 4rem;
          // }
          .layout__main {
            padding: 0 4rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          // .layout__overlayContainer {
          //   padding: 0 4.5rem;
          // }
          .layout__main {
            padding: 0 4.5rem;
          }
        }
        @media (min-width: 1136px) {
          .layout__main {
            min-height: calc(100vh - 714px);
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XL}px) {
        }
      `}</style>
    </>
  )
}

export default observer(PartnershipsLayout)
