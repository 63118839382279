import React from 'react'
import Link from 'next/link'
import { THEME } from 'utils'
import { IconGroup } from 'components/PartnershipsSite/PartnershipsHeader'

const FooterNav = () => {
  const navItems = [
    {
      name: 'Our Verticals',
      link: 'verticals'
    },
    {
      name: 'Sponsorship Opportunities',
      link: 'sponsorships'
    },
    {
      name: 'Partnerships',
      link: 'partners'
    },
    {
      name: 'Contact Us',
      link: 'https://barstool.typeform.com/to/qXSeBIvZ'
    }
  ]

  return (
    <div className='footerNav'>
      {navItems.map(({ name, link }, idx) => (
        <Link key={idx} href={name === 'Contact Us' ? link : `/partnerships/${link}`} legacyBehavior>
          <a>{name}</a>
        </Link>
      ))}
      <style jsx>{`
        .footerNav {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        a {
          margin: 0.1rem 0;
          color: inherit;
          font-weight: bold;
          font-size: 0.6rem;
          letter-spacing: 0.1px;
        }
        a:hover {
          color: #11192a;
        }
        @media (min-width: 370px) {
          a {
            margin: 0.1rem 0;
            font-size: 0.65rem;
          }
        }
        @media (min-width: 400px) {
          a {
            font-size: 0.7rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          a {
            margin: 0.2rem 0;
            font-size: 0.95rem;
          }
        }
        @media (min-width: 720px) {
          a {
            font-size: 1rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .footerNav {
            flex-direction: row;
          }
          a {
            font-size: 0.7rem;
            margin-right: 1.25rem;
          }
        }
        @media (min-width: 870px) {
          a {
            font-size: 0.72rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          a {
            font-size: 0.72rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          a {
            font-size: 0.72rem;
            margin-right: 0;
            margin-left: 1.1rem;
          }
          a:first-of-type {
            margin: 0;
          }
        }
        @media (min-width: 1100px) {
          a {
            font-size: 0.8rem;
            margin-left: 1.3rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XL}px) {
          a {
            font-size: 0.825rem;
          }
        }
      `}</style>
    </div>
  )
}

const PartnershipsFooter = () => {
  return (
    <div className='asFooter'>
      <div className='asFooter__grid'>
        <Link href='/partnerships' legacyBehavior>
          <a className=''>
            <img
              src='https://chumley.barstoolsports.com/union/2021/02/10/BSSA_LogoWhite.1402f515.png?format=png'
              alt='Barstool Sports'
            />
          </a>
        </Link>
        <div className='asFooter__icons'>
          <IconGroup color={'#ffffff'} />
        </div>
        <div className='asFooter__nav'>
          <FooterNav />
        </div>
        <h2>© {new Date().getFullYear()} Barstool Sports. All rights reserved.</h2>
      </div>
      <style jsx>{`
        .asFooter {
          background: #eb3e3e;
        }
        .asFooter__grid {
          background-color: inherit;
          color: #ffffff;
          padding: 1.5rem;
          display: grid;
          grid-template-columns: 1fr;
          justify-items: center;
          max-width: 1200px;
          margin: auto;
        }
        a {
          display: flex;
          align-items: center;
          align-content: center;
        }
        img {
          width: 210px;
        }
        .asFooter__icons {
          margin: 0.6rem;
        }
        h2 {
          font-size: 0.6rem;
          width: 80%;
          margin: 1.5rem auto 0;
          text-align: center;
        }
        @media (min-width: 370px) {
          .asFooter__grid {
            padding: 2rem;
          }
          img {
            width: 255px;
          }
          .asFooter__icons {
            margin: 1rem;
          }
          h2 {
            font-size: 0.7rem;
            margin: 1.75rem auto 0;
          }
        }
        @media (min-width: 400px) {
          img {
            width: 265px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .asFooter__grid {
            padding: 2.5rem;
          }
          img {
            width: 355px;
          }
          .asFooter__icons {
            margin: 1.1rem;
          }
          h2 {
            font-size: 0.95rem;
          }
        }
        @media (min-width: 720px) {
          .asFooter__grid {
            padding: 3rem;
          }
          img {
            width: 375px;
          }
          h2 {
            font-size: 1rem;
            margin: 2rem auto 0;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .asFooter__grid {
            padding: 2rem 3rem;
            grid-template-columns: 1fr 1fr;
            align-items: center;
          }
          a {
            justify-self: start;
          }
          img {
            width: 300px;
          }
          .asFooter__icons {
            margin: 0;
            justify-self: end;
          }
          .asFooter__nav {
            justify-self: start;
            grid-column: 1/3;
            margin: 1rem 0.25rem 2.5rem;
          }
          h2 {
            justify-self: start;
            text-align: left;
            grid-column: 1/3;
            margin: 0;
            font-size: 0.85rem;
          }
        }
        @media (min-width: 870px) {
          .asFooter__grid {
            padding: 2.5rem 3rem;
          }
          img {
            width: 340px;
          }
          h2 {
            font-size: 0.9rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .asFooter__grid {
            padding: 2.5rem 4.5rem;
          }
          img {
            width: 355px;
          }
          h2 {
            font-size: 0.8rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          .asFooter__grid {
            padding: 2.5rem 5.5rem;
          }
          .asFooter__icons {
            margin: 1rem 0 2.5rem;
            justify-self: end;
            grid-column: 1/3;
            grid-row: 2/3;
          }
          .asFooter__nav {
            justify-self: end;
            grid-column: 2/3;
            grid-row: 1/2;
            margin: 0;
          }
          h2 {
            width: 100%;
            font-size: 0.8rem;
            text-align: right;
          }
        }
        @media (min-width: 1100px) {
          .asFooter__grid {
            padding: 2.5rem 5.5rem;
          }
          img {
            width: 375px;
          }
          h2 {
            font-size: 0.9rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XL}px) {
          .asFooter__grid {
            padding: 2.5rem 6rem;
          }
        }
      `}</style>
    </div>
  )
}

export default PartnershipsFooter
