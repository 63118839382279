import React from 'react'
import Link from 'next/link'
import { observer } from 'mobx-react-lite'
import { THEME } from 'utils'
import Twitter from 'icons/Twitter'
import Instagram from 'icons/Instagram'
import Facebook from 'icons/Facebook'
import YouTube from 'icons/Youtube'
import MediaQuery from 'components/MediaQuery'

import { useStore } from 'hooks'
import { NavigationStore } from 'stores/navigation'

export const IconGroup = ({ color }) => (
  <div className='socialContainer'>
    <a
      className='socialLink'
      target='_blank'
      rel='noreferrer noopener'
      href='https://www.instagram.com/barstoolsports/'
    >
      <Instagram color={color} height='auto' width='100%' />
    </a>
    <a className='socialLink' target='_blank' rel='noreferrer noopener' href='https://www.facebook.com/barstoolsports/'>
      <Facebook color={color} height='auto' width='100%' />
    </a>
    <a className='socialLink' target='_blank' rel='noreferrer noopener' href='https://twitter.com/barstoolsports'>
      <Twitter color={color} height='auto' width='100%' />
    </a>
    <a
      className='socialLink'
      target='_blank'
      rel='noreferrer noopener'
      href='https://www.youtube.com/user/itsthebroshowyo'
    >
      <YouTube color={color} height='auto' width='100%' />
    </a>
    <style jsx>{`
      .socialContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        width: 140px;
        align-items: center;
      }
      .socialContainer a {
        height: 22px;
        display: flex;
        justify-content: center;
      }
      .socialLink {
        padding: 0 0.1rem;
      }
      @media (min-width: 370px) {
        .socialContainer {
          width: 170px;
        }
        .socialContainer a {
          height: 28px;
        }
      }
      @media (min-width: 400px) {
      }
      @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
        .socialContainer {
          width: 150px;
        }
        .socialContainer a {
          height: 40px;
        }
      }
      @media (min-width: 720px) {
        .socialContainer {
          width: 160px;
        }
        .socialContainer a {
          height: 45px;
        }
      }
      @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
        .socialContainer a {
          height: 25px;
        }
      }
      @media (min-width: 850px) {
        .socialContainer {
          width: 200px;
        }
      }
      @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
      }
      @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
      }
      @media (min-width: 1100px) {
      }
      @media (min-width: ${THEME.BREAKPOINTS.XL}px) {
      }
    `}</style>
  </div>
)

const HeaderNav = () => {
  const navItems = [
    {
      name: 'Our Verticals',
      link: 'verticals'
    },
    {
      name: 'Sponsorship Opportunities',
      link: 'sponsorships'
    },
    {
      name: 'Partnerships',
      link: 'partners'
    },
    {
      name: 'Contact Us',
      link: 'https://barstool.typeform.com/to/qXSeBIvZ'
    }
  ]

  return (
    <div className='headerNav'>
      {navItems.map(({ name, link }, idx) => (
        <Link key={idx} href={name === 'Contact Us' ? link : `/partnerships/${link}`} legacyBehavior>
          <a>{name}</a>
        </Link>
      ))}
      <style jsx>{`
        .headerNav {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        a {
          margin: 0.1rem 0;
          color: inherit;
          font-weight: bold;
          font-size: 0.6rem;
          letter-spacing: 0.1px;
        }
        a:hover {
          color: #11192a;
        }
        @media (min-width: 370px) {
          a {
            margin: 0.1rem 0;
            font-size: 0.65rem;
          }
        }
        @media (min-width: 400px) {
          a {
            font-size: 0.7rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          a {
            margin: 0.2rem 0;
            font-size: 0.95rem;
          }
        }
        @media (min-width: 720px) {
          a {
            font-size: 1rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .headerNav {
            flex-direction: row;
          }
          a {
            font-size: 0.75rem;
            margin-right: 1.25rem;
          }
        }
        @media (min-width: 870px) {
          a {
            font-size: 0.8rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          a {
            font-size: 0.78rem;
            margin-right: 0;
            margin-left: 1.25rem;
          }
          a:first-of-type {
            margin: 0;
          }
        }
        @media (min-width: 1100px) {
          a {
            font-size: 0.84rem;
            margin-left: 1.5rem;
          }
        }
        @media (min-width: 1150px) {
          a {
            font-size: 0.85rem;
            margin-left: 1.75rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XL}px) {
          // a {
          //   font-size: .825rem;
          // }
        }
      `}</style>
    </div>
  )
}

const MobileDropdown = () => {
  const navItems = [
    {
      name: 'Our Verticals',
      link: '/partnerships/verticals'
    },
    {
      name: 'Sponsorship Opportunities',
      link: '/partnerships/sponsorships'
      // subNav: [
      //   { name: 'Audio', link: 'audio' },
      //   { name: 'Video', link: 'video' },
      //   { name: 'Social', link: 'social' },
      //   { name: 'Display', link: 'display' },
      //   { name: 'Experiential', link: 'experiential' },
      //   { name: 'Self Service', link: 'self-service' }
      // ]
    },
    {
      name: 'Partnerships',
      link: 'partners'
    },
    {
      name: 'Contact Us',
      link: 'https://barstool.typeform.com/to/qXSeBIvZ'
    }
  ]

  return (
    <>
      <div className='dropdown'>
        <ul className='nav__outer'>
          {navItems.map(({ name, link, subNav }, idx) => (
            <li key={idx}>
              <Link href={link} legacyBehavior>
                <a>{name}</a>
              </Link>
              {subNav && (
                <ul className='nav__inner'>
                  {subNav.map(({ name, link }, idx) => (
                    <li key={idx}>
                      <Link href={link} legacyBehavior>
                        <a>{name}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      <style jsx>{`
        .dropdown {
          width: 100%;
          background: #ffffff;
          padding: 0 1.25rem 0.5rem;
        }
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          font-family: HelveticaNeue;
          font-weight: bold;
          width: 100%;
        }
        .nav__outer > li {
          padding: 0.5rem 0;
        }
        .nav__outer > li:first-of-type {
          padding: 0 0 0.5rem;
        }
        a {
          color: #eb3e3e;
          font-size: 4.5vw;
        }
        .nav__inner > li {
          padding: 0.25rem 1.25rem;
        }
        .nav__inner a {
          font-weight: bold;
          color: #000000;
          font-size: 0.7rem;
        }
        @media (min-width: 370px) {
          .nav__inner {
            margin-top: 0.2rem;
          }
          .nav__inner > li {
            padding: 0.4rem 1.6rem;
          }
          .nav__inner > li:last-of-type {
            padding-bottom: 0;
          }
          .nav__inner a {
            font-size: 0.85rem;
          }
        }
        @media (min-width: 400px) {
          .dropdown {
            padding: 0 1.25rem 1rem;
          }
          .nav__inner a {
            font-size: 0.9rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .dropdown {
            padding: 0rem 1.75rem 1rem;
          }
          .nav__outer > li {
            padding: 2.5% 0;
          }
          a {
            // font-size: 1.6rem;
            font-size: 3.5vw;
          }
          .nav__inner {
            margin-top: 0.2rem;
          }
          .nav__inner > li {
            padding: 0.5rem 2rem;
          }
          .nav__inner a {
            font-size: 1.1rem;
          }
        }
        @media (min-width: 700px) {
          // a {
          //   font-size: 1.7rem;
          // }
          .nav__inner > li {
            padding: 0.6rem 2rem;
          }
          .nav__inner a {
            font-size: 1.2rem;
          }
        }
      `}</style>
    </>
  )
}

const PartnershipsHeader = ({ mobileMenu, setMobileMenu }) => {
  const navigationStore = useStore(NavigationStore)

  const handleMobileMenu = () => {
    setMobileMenu((prev) => {
      prev ? navigationStore.close() : navigationStore.open()
      return !prev
    })
  }

  return (
    <header className='asHeader'>
      <div className='asHeader__grid'>
        <Link href='/partnerships' legacyBehavior>
          <a className=''>
            <img
              src='https://chumley.barstoolsports.com/union/2021/02/10/BSSA_Logo.bd07a46f.png?format=png'
              alt='Barstool Sports'
            />
          </a>
        </Link>
        <MediaQuery maxWidth={THEME.BREAKPOINTS.SM - 1}>
          <img
            className='mobile_menu_icon'
            src={`https://chumley.barstoolsports.com/union/2021/02/02/${
              !mobileMenu ? 'mobile_menu_icon.d9b23b29' : 'tempX.34d078ed'
            }.png?format=png`}
            alt='menu_icon'
            onClick={handleMobileMenu}
          />
        </MediaQuery>
        <MediaQuery minWidth={THEME.BREAKPOINTS.SM}>
          <div className='asHeader__nav'>
            <HeaderNav />
          </div>
          <div className='asHeader__icons'>
            <IconGroup color={'#eb3e3e'} />
          </div>
        </MediaQuery>
      </div>
      <MediaQuery maxWidth={THEME.BREAKPOINTS.SM - 1}>{mobileMenu && <MobileDropdown />}</MediaQuery>
      <style jsx>{`
        .asHeader {
          background: #ffffff;
        }
        .asHeader__grid {
          background-color: inherit;
          color: #eb3e3e;
          padding: 1rem 1rem;
          display: grid;
          grid-template-columns: 4fr 1fr;
          align-items: center;
          justify-items: center;
          max-width: 1200px;
          margin: auto;
        }
        a {
          display: flex;
          justify-self: start;
          align-items: center;
          align-content: center;
          // width: 190px;
          width: 92%;
        }
        img {
          height: auto;
          // width: 190px;
          width: 94%;
        }
        .mobile_menu_icon {
          justify-self: end;
          height: auto;
          // width: 18px;
          width: 30%;
          cursor: pointer;
        }
        @media (min-width: 400px) {
          .asHeader__grid {
            padding: 1.25rem 1rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}px) {
          .asHeader__grid {
            padding: 1.5rem 1.25rem;
          }
          img {
            width: 80%;
          }
          .mobile_menu_icon {
            width: 25%;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .asHeader__grid {
            padding: 1.5rem 3rem 1rem;
            // grid-template-columns: 1fr 1fr;
            row-gap: 0.75rem;
          }
          a,
          a > img {
            width: 410px;
          }
          .asHeader__nav {
            grid-column: 1/2;
            grid-row: 2/3;
            justify-self: start;
            padding-left: 0.25rem;
          }
          .asHeader__icons {
            grid-column: 2/3;
            grid-row: 2/3;
            justify-self: end;
          }
          .mobile_menu_icon {
            display: none;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}px) {
          .asHeader__grid {
            padding: 1.5rem 4rem 1rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}px) {
          .asHeader__grid {
            padding: 1.75rem 4.5rem 1rem;
            grid-template-columns: 1fr 2fr;
            row-gap: 1rem;
          }
          a,
          a > img {
            width: 390px;
          }
          .asHeader__nav {
            grid-column: 2/3;
            grid-row: 1/2;
            justify-self: end;
            padding-left: 0.25rem;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.XL}px) {
        }
      `}</style>
    </header>
  )
}

export default observer(PartnershipsHeader)
